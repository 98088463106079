import Image from './Image';

type Props = {
  title: string;
  data: any;
  height?: number;
  width?: number;
  quality?: number;
  sizes?: string;
};
function BlurImage(props: Props) {
  const { sizes, title, data, width, height, quality = 75 } = props;
  return (
    <Image
      src={data.src}
      alt={title}
      blurDataURL={data.blurDataURL}
      width={width}
      height={height}
      quality={quality}
      placeholder="blur"
      sizes={sizes}
      className="absolute inset-0 h-full w-full object-contain px-4 mix-blend-multiply"
    />
  );
}

BlurImage.displayName = 'BlurImage';

export default BlurImage;
