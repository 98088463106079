import Image from '../Image/Image';
import { cn } from '@ngg/storefront-utils';
import BlurImage from '../Image/BlurImage';

type Props = {
  title: string;
  src?: string;
  alternateSrc?: string;
  data?: any;
  width?: number;
  height?: number;
  quality?: number;
  priority?: boolean;
};
const Thumbnail = (props: Props) => {
  const {
    title,
    src,
    alternateSrc,
    data,
    width = 400,
    height = 400,
    quality = 50,
    priority,
  } = props;

  if (data)
    return (
      <BlurImage
        data={data}
        title={title}
        width={width}
        height={height}
        sizes="(max-width: 768px) 75vw, (max-width: 1024px) 33vw, 20vw"
        quality={quality}
      />
    );
  if (!src) return null;
  return (
    <figure className={cn('group absolute inset-0 h-full w-full')}>
      <Image
        src={src}
        alt={title}
        fill
        quality={quality}
        sizes="(max-width: 768px) 75vw, (max-width: 1024px) 33vw, 20vw"
        className={cn(
          'absolute inset-0 h-full w-full',
          'object-contain mix-blend-multiply',
          'p-2',
          !alternateSrc &&
            'transition-all duration-150 ease-linear lg:hover:scale-110',
        )}
        priority={priority}
      />
      {alternateSrc && (
        <Image
          src={alternateSrc}
          alt={title}
          fill
          quality={quality}
          sizes="(max-width: 768px) 75vw, (max-width: 1024px) 33vw, 20vw"
          className={cn(
            'absolute inset-0 h-full w-full',
            'object-cover opacity-0',
            'transition-opacity group-hover:opacity-100',
            'hidden lg:block',
          )}
          priority={priority}
        />
      )}
    </figure>
  );
};
export default Thumbnail;
