import { cn } from '@ngg/storefront-utils';
import { formatPrice } from '@utils/currency/formatPrice';
import * as styles from './Price.styles';
import type { Dictionary } from '@ts/dictionary';
import Paragraph from '../Paragraph/Paragraph';

export default function Price({
  className,
  productType = 'Product',
  priceDetails,
  dictionary,
}: {
  className?: string | null;
  productType?: string;
  dictionary?: Dictionary;
  priceDetails: {
    priceType?: string;
    fromPrice?: boolean | null;
    price?: number | null;
    presentationPrice?: number | null;
    presentationOriginal?: number | null;
    originalPrice?: number | null;
  };
}) {
  const isVariant = productType == 'Variant';
  return (
    <div className={cn(styles.container(), className)}>
      {priceDetails?.priceType === 'priceMatch' && (
        <div className={styles.comparisonPriceContainer()}>
          <Paragraph className={styles.mainPriceParagraph()}>
            {priceDetails.fromPrice && `${dictionary?.from} `}
            {formatPrice(
              isVariant ? priceDetails.price : priceDetails.presentationPrice,
            )}
          </Paragraph>
          <Paragraph className={styles.comparisionPriceParagraph()}>
            ({dictionary?.recommendedPrice}{' '}
            {formatPrice(
              isVariant
                ? priceDetails.originalPrice ?? priceDetails.price
                : priceDetails.presentationOriginal ??
                    priceDetails.presentationPrice,
            )}
            )
          </Paragraph>
        </div>
      )}
      {priceDetails?.priceType === 'campaign' && (
        <div className={styles.comparisonPriceContainer()}>
          <Paragraph className={styles.mainPriceParagraph()}>
            {priceDetails.fromPrice && `${dictionary?.from} `}
            {formatPrice(
              isVariant ? priceDetails.price : priceDetails.presentationPrice,
            )}
          </Paragraph>
          <Paragraph
            className={cn([
              styles.comparisionPriceParagraph(),
              'line-through',
            ])}>
            {formatPrice(
              isVariant
                ? priceDetails.originalPrice ?? priceDetails.price
                : priceDetails.presentationOriginal ??
                    priceDetails.presentationPrice,
            )}
          </Paragraph>
        </div>
      )}
      {priceDetails?.priceType === 'standard' && (
        <Paragraph className={styles.mainPriceParagraph()}>
          {priceDetails.fromPrice && `${dictionary?.from} `}
          {formatPrice(
            isVariant ? priceDetails.price : priceDetails.presentationPrice,
          )}
        </Paragraph>
      )}
    </div>
  );
}
