/* eslint-disable no-console */

// Some of these entries are client only.

import dynamic from 'next/dynamic';
import { camelCase } from '@utils/stringUtils';

const Accordion = dynamic(
  () => import('@ui/components/blocks/RichTextAccordion'),
);
const EditorialSplash = dynamic(
  () => import('@ui/components/blocks/EditorialSplash') as any,
);
const Store = dynamic(() => import('@ui/components/blocks/Store'));
const Form = dynamic(() => import('@ui/components/blocks/Form'));
const NavigationLink = dynamic(
  () => import('@ui/components/blocks/RichTextNavigationLink'),
);

const DYNAMIC_COMPONENTS = {
  accordion: Accordion,
  EditorialSplash,
  store: Store,
  form: Form,
  navigationLink: NavigationLink,
} as { [key: string]: (props: any) => JSX.Element };

const Server = async ({
  __typename,
  ...props
}: any & { __typename: string }) => {
  let data;
  const Component = DYNAMIC_COMPONENTS[camelCase(__typename ?? '')];
  if (Component) return <Component {...props} data={data} />;
  console.group(
    console.error(`Unable to render component ${__typename}`),
    console.error(props),
  );
  return null;
};

const Client = ({ __typename, ...props }: any & { __typename: string }) => {
  let data;
  const Component = DYNAMIC_COMPONENTS[camelCase(__typename ?? '')];
  if (Component) return <Component client {...props} data={data} />;
  console.group(
    console.error(`Unable to render component ${__typename}`),
    console.error(props),
  );
  return null;
};

// The *@client* prop regulates if the component should be rendered client or server-side, per default the component will render server-side
export default function ClientFriendlyEntry({
  client = false,
  ...props
}: any & { client: boolean }) {
  if (client) return <Client {...props} />;

  return <Server {...props} />;
}
