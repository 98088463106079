const SLUG_PAGE_NUMBER_REGEX = /^\d+$/;

export const getExternalStoreId = (market: string) => {
  switch (market) {
    case 'se':
    case 'no':
      return `ecom-${market}`;
    default:
      return 'ecom-lanna';
  }
};

export const MARKET_LINKS = [
  {
    href: 'https://lannamobler.se',
    label: 'Sverige',
  },
  {
    href: 'https://lanna.no',
    label: 'Norge',
  },
];

export const getSlugWithoutPageNumber = (slug?: string[]): string[] =>
  slug?.length
    ? slug.filter((s: string) => s.search(SLUG_PAGE_NUMBER_REGEX) === -1)
    : [];
